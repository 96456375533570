import React, { useState } from "react";
import { Col, Row, Carousel } from "react-bootstrap";
import testimonialData from "../data/testimonialData";
import "../../styles/testimonials.css";

const Testimonials = ({ classNameSection }) => {

  const [indexTestimonials, setIndexTestimonials] = useState(0);

  const handleTestimonials = (selectedIndex, e) => {
    setIndexTestimonials(selectedIndex);
  };
  const testimonials = testimonialData();
  const renderTestimonials = testimonials.list.map((e, i) => {
    return (
      <Carousel.Item key={i}>
        <div className="slide-inner">
          <Row>
            <Col xs="12" md="12" lg="2" className="user-avatar text-center">
              <img src={e.img} alt="tester avatar" className="tester-avatar" />
              <h5>{e.author}</h5>
            </Col>
            <Col
              xs="12"
              md="12"
              lg="10"
              className="align-self-center testimonial-content"
            >
              <p className="p-3">{e.text}</p>
            </Col>
          </Row>
        </div>
      </Carousel.Item>
    );
  });
  return (
    <section
      className="tesimonials__external common__section"
      id="testimonials"
    >
      <div className={classNameSection}>
        <h3>{testimonials.title}</h3>
        {testimonials.desc !== "" ? <p>{testimonials.desc}</p> : null}

        <Carousel
          activeIndex={indexTestimonials}
          interval={5000}
          indicators={false}
          onSelect={handleTestimonials}
        >
          {renderTestimonials}
        </Carousel>
      </div>

        <div className="waveNew waveTop"></div>
        <div className="waveNew waveMid"></div>
        <div className="waveNew waveBot"></div>

    </section>
  );
};

export default Testimonials;
