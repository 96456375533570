export default function howToStartData() {
  return {
    title: "Как начать возвращать деньги уже сейчас",
    descr:
      "Чтобы мы могли помочь Вам вернуть Ваши деньги, Вы должны совершить всего лишь три простых действия:",
    list: [
      {
        text: "Оставить заявку со своими контактными данными и получить бесплатную консультацию",
        color: "#44f2f2",
      },
      {
        text: "Подписать с нами договор на оказание услуг по возврату средств",
        color: "#9bf244",
      },
      {
        text: "Получить назад свои деньги",
        color: "#44f2f2",
      },

    ],
    afterText: "Обращаем Ваше внимание на тот факт, что ticktrue.com не берет со своих клиентов предоплаты. Оплата наших услуг происходит только после того как Вы получили назад свои деньги."
  };
}