import React from "react";
//import { Link } from "react-router-dom";
import "../../styles/navbar.css"
import close from "../../images/close.svg"


const MenuInner = ({ openClose, burgerClick }) => {
  const classNameNav =()=> {
    return burgerClick === 1 ? "nav container show" : "nav container hidden";
  }
  return burgerClick !== 0 ? (
    <div className={classNameNav()} >
      <a href="#home" onClick={openClose} className="nav-link">Главная</a>      
      <a href="#second" onClick={openClose} className="nav-link">Важно действовать</a>       
      <a href="#whywe" onClick={openClose} className="nav-link">Почему мы</a>
      <a href="#howToStart" onClick={openClose} className="nav-link">Как начать</a>    
      <a href="#testimonials" onClick={openClose} className="nav-link">Отзывы</a>
      <img src={close} alt="close menu" className="closeForm" onClick={openClose} />
    </div>
  ): null;
};

export default MenuInner;