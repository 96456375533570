import React from "react";
import burger from "../../images/burger-bar.svg";
import "../../styles/menu.css";
import MenuInner from "./MenuInner";

const Menu = ({toggleClick, burgerClick}) => {
  const classNameImg = () => {
    switch (burgerClick) {
      case 0:
        return "burgerImg";
      case 1:
        return "burgerImg rotate";
      case 2:
        return "burgerImg unrotate";
      default:
        return "burgerImg";
    }
  };
  return (
    <>
      <div
        className="menu"
        onClick={() => {
          toggleClick();
        }}
      >
        <img src={burger} alt="burger" className={classNameImg()} />
      </div>
      <MenuInner burgerClick={burgerClick} openClose={toggleClick} />
    </>
  );
};

export default Menu;
