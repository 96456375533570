import customer1 from "../../images/customers/man3.jpg";
import customer2 from "../../images/customers/man4.jpg";

export default function testimonialData() {
  return {
    title: "Наши клиенты о ticktrue.com",
    desc: "Люди, которым мы уже помогли вернуть деньги",
    list: [
      {
        img: customer1,
        author: "Дмитрий Портников",
        text: "Связался с брокером-мошенником, но хуже всего, пополнил свой счет криптовалютным переводом. Все, в том числе в полиции, говорили что шансов нет. Но к счастью я обратился в ticktrue.com и они каким-то чудом справились. Не скажу что все произошло мгновенно, но в конечном мои деньги вернули.",
      },
      {
        img: customer2,
        author: "Ярослав Борисов",
        text: "Не скажу что я многое потерял, но я сделал довольно удачную ставку на серию матчей и в итоге сорвал отличный куш. А букмекер отказался выплачивать выигрыш. Пытались вернуть мою ставку и все на том. К счастью ticktrue.com смогли развернуть ситуацию и “добыть” мои честно выигранные деньги.",
      },
    ],
  };
}
