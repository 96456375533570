import React from "react";
import "../../styles/banner.css";
import bannerData from "../data/bannerData";


const Banner = ({ classNameSection }) => { 
   const data = bannerData();

  return (
    <section className="waveWrapper waveAnimation banner" id="home">
      <div className="waveWrapperInner bgTop">
        <div className="wave waveTop"></div>
      </div>
      <div className="waveWrapperInner bgMiddle">
        <div className="wave waveMiddle"></div>
      </div>
      <div className="waveWrapperInner bgBottom">
        <div className="wave waveBottom"></div>
      </div>
      <div className={classNameSection}> 
        <div className="bannerLogo">
          <img src={data.logo} alt="logo" />
        </div>
        <div className="bannerText">
          <h1>{data.title}</h1>
          <h2>{data.firstBlock}</h2>
          {data.secondBlock ? (
            <p className="banner-descr">{data.secondBlock}</p>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Banner;
