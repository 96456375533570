import React from "react";
import secondData from "../data/secondData";
import "../../styles/second.css";
import text from "../../images/secondText.svg"
import image from "../../images/secondImg.svg"
import LeadForm from "./LeadForm";

const Second = ({classNameSection }) => {
  const data = secondData();

  return <section className="second common__section" id="second">
    <div className={classNameSection}>
      <div className="leftBlock blocks">
        <h2>{data.titleLeft}</h2>
        <p>{data.textLeft}</p>
        <div className="imgBlock">
          <img src={text} alt="back money" className="textImage"/>
          <img src={ image } alt="back money" className="image"/>
        </div>
      </div>
      <div className="rightBlock blocks">
        <h2>{data.titleRight}</h2>
        <p>{data.textRight}</p>
        <LeadForm />
        
      </div>

    </div>

  </section>;
};

export default Second;
