import React, { useState, useRef, useLayoutEffect} from "react";
import howTo from "../data/howToStartData";
import "../../styles/howToStart.css";

const HowToStart = ({ classNameSection }) => {
  const [show, doShow] = useState(false);
  const ourRef = useRef(null)

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const div1Pos = topPos(ourRef.current)

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;
      
      if (div1Pos < scrollPos) {
        doShow(true);
      } else {
        doShow(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const data = howTo();

  return (
    <section className={`howToStart common__section ${show && 'active'}`} id="howToStart"  ref={ourRef}>
      <div className={classNameSection}>
        <h2>{data.title}</h2>
        <p>{data.descr}</p>
        <ul className="list">
          {data.list.map((e, i) => <li key={i} style={{borderColor: e.color}}>{e.text}</li>)}
        </ul>
        <p className="afterText">{data.afterText}</p>
      </div>
    </section>
  );
};

export default HowToStart;
