export default function whyWe() {
  return {
    title: "Почему именно ticktrue.com",
    descr:
      "Мы обладаем целым рядом неоспоримых преимуществ. Обращаясь к нам за помощью Вы можете быть уверены что:",
    list: [
      {
        text: "Вашим делом занимаются профессионалы с многолетним опытом возврата денег",
        color: "#44f2f2",
      },
      {
        text: "Вам не придется тратить свое время и силы на обременительные бюрократические и другие процедуры",
        color: "#9bf244",
      },
      {
        text: "Вы получите свои деньги максимально быстро, всего за пару недель",
        color: "#44f2f2",
      },
      {
        text: "Вся полученная от Вас информация будет хранится в тайне и не подлежит разглашению, в том числе и правоохранительным органам",
        color: "#9bf244",
      },
    ],
  };
}
