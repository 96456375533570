import logo from "../../images/logoNewLight.svg";
export default function slidesData() {
  return {
    title: "ticktrue.com",
    firstBlock: "Самое время вернуть Ваши деньги",
    secondBlock:
      "Что бы не случилось с вашими деньгами, брокер-мошенник, финансовая пирамида, отказ в выводе выигрыша из казино, ticktrue.com поможет вернуть Ваши деньги быстро и эффективно. Не стоит терять время.",
    btn: "Получить бесплатную консультацию",
    logo: logo,
  };
}