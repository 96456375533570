import React, { useState, useEffect } from "react";
import "./App.css";
import Banner from "./js/components/Banner";
import HowToStart from "./js/components/HowToStart";
import Menu from "./js/components/Menu";
import Second from "./js/components/Second";
import Testimonials from "./js/components/Testimonials";
import WhyWe from "./js/components/WhyWe";
import getWindowDimensions from "./js/functions/getWinSizes.js";
import { ContextProvider } from "./js/data/Context.js";
import WhatsAppIcon from './whatsapp/WhatsAppIcon';

function App() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      //setModalVisible(false);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const wd = windowDimensions.width;
  const hd = windowDimensions.height;
  const global = {
    wd: wd,
    hd: hd,
  };
  const [burgerClick, setBurgerClick] = useState(0);

  const toggleClick = () => {
    switch (burgerClick) {
      case 0:
        setBurgerClick(1);
        break;
      case 1:
        setBurgerClick(2);
        break;
      case 2:
        setBurgerClick(1);
        break;
      default:
        setBurgerClick(0);
    }
  };

  const classNameSection = () => {
    if (wd <= 1200) {
      return "container";
    }
    switch (burgerClick) {
      case 0:
        return "container";
      case 1:
        return "container move";
      case 2:
        return "container unmove";
      default:
        return "container";
    }
  };
  return (
    <ContextProvider value={global}>
      <div className="App">
        <Menu toggleClick={toggleClick} burgerClick={burgerClick} />
        <div className="bannerTop">
          <Banner classNameSection={classNameSection()} />
        </div>
        <Second classNameSection={classNameSection()} />
        <WhyWe classNameSection={classNameSection()} />
        <HowToStart classNameSection={classNameSection()} />
        <Testimonials classNameSection={classNameSection()} />
        <WhatsAppIcon />
      </div>
    </ContextProvider>
  );
}

export default App;
